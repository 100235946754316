import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
//JM: Implementar Actions de la pagina de ventasSalidaRuta y activar este import
//import { registrarVenta } from "../../actions/ventaActions";
import {
  StyledButtonCancelar,
  StyledButtonConfirmar,
  StyledCol,
  StyledFormGroup,
  StyledModal,
  StyledRow,
} from "./styles/VentanaAvisoVisita.styles";
import { realizarAvisoVisita } from "../../actions/salidaRutaActions";
import {
  RESET_SALIDA_RUTA_AVISO_VISITA,
  RESET_SALIDA_RUTA_DETALLES,
} from "../../constantes/salidaRutaConstantes";
import { useReactToPrint } from "react-to-print";
import TicketAvisoVisita from "./TicketAvisoVisita";
import toast from "react-hot-toast";

const VentanaAvisoVisita = ({
  cliente,
  salidaRuta,
  mostrarVenta,
  setMostrarVenta,
}) => {
  // Funcion para disparar acciones
  const dispatch = useDispatch();

  const [observaciones, setObservaciones] = useState("");

  //Referencias de los botones
  const confirmarRef = useRef(null);
  const cancelarRef = useRef(null);
  const componentRef = useRef(null);

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  // Funcion para realizar la venta
  const manejarRealizarAvisoVisita = (e) => {
    e.preventDefault();
    setDisabledState(!disabledState);

    // Disparar la accion para realizar aviso de visita y cerrar ventana
    const avisoVisita = {
      CLIENTE_SALIDA_RUTA: cliente.id,
    };

    console.log("aviso visita", avisoVisita);

    dispatch(realizarAvisoVisita(avisoVisita, salidaRuta.id));
  };

  const salidaRutaAvisoVisita = useSelector(
    (state) => state.salidaRutaAvisoVisita
  );

  const { loading, success, error } = salidaRutaAvisoVisita;

  useEffect(() => {
    if (loading) {
      toast.dismiss();
      toast.loading("Realizando aviso de visita");
    }

    if (success) {
      toast.dismiss();
      toast.success("Aviso de visita realizado exitosamente");
    }

    if (error) {
      toast.dismiss();
      toast.error("Un error ocurrio al hacer el aviso de visita");
    }
  });

  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      dispatch({ type: RESET_SALIDA_RUTA_AVISO_VISITA });
      dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
      setMostrarVenta(false);
    },
  });

  useEffect(() => {
    if (success) {
      manejarPrint();
    }
  }, [success, manejarPrint]);

  return (
    salidaRuta && (
      <>
        <StyledModal
          centered
          show={mostrarVenta}
          onHide={() => setMostrarVenta(false)}>
          <Modal.Header>
            <h4>
              Generar ticket de visita a rutas para cliente:{" "}
              {cliente.CLIENTE_NOMBRE}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <StyledRow>
              <StyledCol xs={6} offset={2}>
                <Form onSubmit={manejarRealizarAvisoVisita}>
                  <StyledFormGroup controlId="observaciones">
                    <Form.Label>Motivo del aviso de visita</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={observaciones}
                      onChange={(e) =>
                        setObservaciones(e.target.value)
                      }></Form.Control>
                  </StyledFormGroup>
                  <StyledButtonConfirmar
                    color="green"
                    disabled={!observaciones || disabledState}
                    ref={confirmarRef}
                    type="submit"
                    onFocus={() => confirmarRef.current.blur()}>
                    Confirmar
                  </StyledButtonConfirmar>
                </Form>
              </StyledCol>
            </StyledRow>
          </Modal.Body>
          <Modal.Footer>
            <StyledButtonCancelar
              color="red"
              variant="danger"
              onClick={() => setMostrarVenta(false)}
              ref={cancelarRef}
              onFocus={() => cancelarRef.current.blur()}>
              Cancelar
            </StyledButtonCancelar>
          </Modal.Footer>
        </StyledModal>
        <div style={{ display: "none" }}>
          <TicketAvisoVisita
            datosCliente={{
              nombre: cliente.CLIENTE_NOMBRE,
              observaciones,
            }}
            repartidor={salidaRuta.REPARTIDOR_NOMBRE}
            ref={componentRef}
          />
        </div>
      </>
    )
  );
};

export default VentanaAvisoVisita;

import { Container } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../componentes/general/styles/Boton.styles";

export const StyledGridContainer = styled.div`
  position: fixed;
  height: 88vh;
  width: 100%;
  padding: 0px 15px;
  overflow: auto;
  // Modificación de la scrollbar para Chrome y Edge
  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgb(105, 133, 169);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(230, 230, 230);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(150, 150, 150);
    border-radius: 5px;
  }

  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2fr 6fr;
  grid-template-rows: 1fr 70vh 1fr;
  grid-template-areas:
    "PanelControl Titulo"
    "PanelControl ContenidoPrincipal";

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 8fr;
    grid-template-areas:
      "Titulo"
      "ContenidoPrincipal";
  }
`;

export const StyledTitulo = styled.h1`
  grid-area: Titulo;
  text-align: center;
  color: white;
  margin-top: 1rem;
`;

export const StyledBoton = styled(StyledButton)`
  /* width: 100%; */
  max-width: 200px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;
  font-size: 0.9rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    min-width: 160px;
  }
`;

export const StyledBotonPanel = styled(StyledButton)`
  display: none;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 5;
  width: 20px;
  color: ${(props) => props.fontcolor};

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;

    i {
      transform: ${(props) => (props.state ? "rotate(180deg)" : "rotate(0)")};
      transition: ease all 0.5s;
    }
  }
`;

export const StyledContenidoPrincipal = styled.div`
  grid-area: ContenidoPrincipal;
  overflow: auto;
  padding: 1rem 0;
  padding-top: 0;
  position: absolute;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

// Estilos del contenedor principal
export const StyledContainer = styled(Container)`
  height: 88vh;
  padding: 1rem 0;
  overflow: auto;

  h1 {
    text-align: center;
    color: var(--white-color);
    margin-bottom: 3rem;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

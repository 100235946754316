import React, { useRef, useState } from "react";
import { StyledBoton, TableStyled } from "./styles/TablaProductos.styles";
import VentanaDevolucion from "./VentanaDevolucion";

const TablaProductos = ({ productos, salidaRuta }) => {
  // Obtener referencia del boton
  const avisoRef = useRef(null);

  const [producto, setProducto] = useState({});
  const [mostrarVenta, setMostrarVenta] = useState(false);

  const manejarRealizarDevolucion = (productoId) => {
    const productoSeleccionado = {
      ...productos.find((c) => c.id === productoId),
    };

    setProducto(productoSeleccionado);
    setMostrarVenta(true);
  };

  // Verificar si es administrador para renderizar algunas propiedades
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // Verificar si es repartidor
  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      {salidaRuta && (
        <TableStyled striped hover>
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th>CARGADO</th>
              <th>DISPONIBLE</th>

              <th>DEVOLUCION</th>
            </tr>
          </thead>

          <tbody>
            {productos.map((producto) => {
              const {
                id,
                CANTIDAD_RUTA,
                CANTIDAD_DISPONIBLE,
                PRODUCTO_NOMBRE,
              } = producto;

              return (
                <tr key={id}>
                  <td>{PRODUCTO_NOMBRE}</td>
                  <td>{CANTIDAD_RUTA}</td>
                  <td>{CANTIDAD_DISPONIBLE}</td>

                  <td>
                    <StyledBoton
                      color="standard"
                      fontcolor={"var(--active-blue-button)"}
                      ref={avisoRef}
                      disabled={
                        !(
                          (isAdmin || role === "CAJERO") &&
                          (salidaRuta.STATUS === "PROGRESO" || salidaRuta.STATUS === "PENDIENTE") &&
                          CANTIDAD_DISPONIBLE > 0
                        )
                      }
                      onFocus={() => avisoRef.current.blur()}
                      onClick={() => manejarRealizarDevolucion(id)}
                    >
                      <i className="fa-solid fa-rotate-left" />
                    </StyledBoton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableStyled>
      )}
      <VentanaDevolucion
        producto={producto}
        salidaRuta={salidaRuta}
        mostrarVenta={mostrarVenta}
        setMostrarVenta={setMostrarVenta}
      />
    </>
  );
};

export default TablaProductos;

import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";

// Estilos de la pagina
import {
  StyledContainer,
  StyledRow,
  StyledCol,
  StyledBoton,
  StyledFormGroup,
} from "./styles/DevolucionDetalles.styles";
import { toast } from "react-hot-toast";
import {
  RESET_DEVOLUCION_ACTUALIZAR,
  RESET_DEVOLUCION_DETALLES,
} from "../constantes/devolucionConstantes";
import {
  actualizarDevolucion,
  obtenerDevolucionDetalles,
} from "../actions/devolucionActions";

const DevolucionDetalles = () => {
  // Obtener el id de la ruta dia
  const params = useParams();
  const devolucionId = params.id;
  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Obtener el estado desde el Redux store
  const devolucionDetalles = useSelector((state) => state.devolucionDetalles);
  const { loading, devolucion, error } = devolucionDetalles;

  // Obtener el estado desde el Redux store
  const devolucionActualizar = useSelector(
    (state) => state.devolucionActualizar
  );
  const {
    loading: actualizarLoading,
    success: actualizarSuccess,
    error: actualizarError,
  } = devolucionActualizar;

  // Obtener la referencia de los botones
  const regresarRef = useRef(null);
  const actualizarRef = useRef(null);

  const [status, setStatus] = useState();

  useEffect(() => {
    // Si no hay ruta o la ruta no es el que seleccione, disparar la accion de
    // obtener ruta
    if (!devolucion || devolucion.id !== Number(devolucionId)) {
      dispatch(obtenerDevolucionDetalles(devolucionId));
    } else {
      setStatus(devolucion.STATUS);
    }
  }, [dispatch, devolucion, devolucionId]);

  // useEffect para mostrar las alertas de actualizar ruta
  useEffect(() => {
    if (actualizarLoading) {
      toast.loading("Actualizando devolucion");
    }

    if (actualizarSuccess) {
      toast.remove();
      toast.success("La actualización fue exitosa");

      dispatch({ type: RESET_DEVOLUCION_ACTUALIZAR });
      navigate("/devoluciones");
    }

    if (actualizarError) {
      toast.dismiss();
      toast.error("Error al actualizar devolucion");
    }
  }, [
    actualizarSuccess,
    actualizarError,
    actualizarLoading,
    devolucion,
    dispatch,
    navigate,
  ]);

  const manejarActualizarDevolucion = (e) => {
    e.preventDefault();

    const devolucionActualizada = {
      // El id es para el endpoint, no como informacion de actualizacion
      id: devolucionId,
      // SI RESETEAS LA RUTADIA ENTONCES SE LE ASIGNA EL REPARTIDOR DE RUTA
      STATUS: status,
      ADMINISTRADOR: ADMINISTRADOR,
    };

    // Disparar la accion de actualizar ruta dia
    dispatch(actualizarDevolucion(devolucionActualizada));
  };

  const ADMINISTRADOR = JSON.parse(localStorage.getItem("name"));

  const manejarRegresar = () => {
    // Redireccionar a la pagina de productos
    dispatch({ type: RESET_DEVOLUCION_DETALLES });
    navigate("/devoluciones");
  };

  // Renderizar loading si se esta cargando la informacion de la devolucion
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la informacion de la devolucion
  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la informacion de la devolucion
        </Mensaje>
      </StyledContainer>
    );

  return (
    devolucion && (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <h1>Devolución #{devolucion.id}</h1>
            <div>
              <StyledBoton
                color="green"
                ref={regresarRef}
                onFocus={() => regresarRef.current.blur()}
                onClick={manejarRegresar}
              >
                Regresar
              </StyledBoton>
            </div>
          </StyledCol>
        </StyledRow>

        <Form onSubmit={manejarActualizarDevolucion}>
          <StyledRow>
            <StyledCol md={4}>
              <StyledFormGroup controlId="repartidor">
                <Form.Label>STATUS</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="PENDIENTE">PENDIENTE</option>
                  <option value="REALIZADO">REALIZADO</option>
                </Form.Control>
              </StyledFormGroup>
              <div className="mt-3">
                <StyledBoton
                  color="green"
                  ref={actualizarRef}
                  onFocus={() => actualizarRef.current.blur()}
                  type="submit"
                >
                  Actualizar devolucion
                </StyledBoton>
              </div>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledContainer>
    )
  );
};

export default DevolucionDetalles;

export const crearProductosVentaConSubtotal = (productosVenta) => {
  const nuevosProductosVenta = productosVenta.map((pv) => {
    const productoId = pv.id;

    const cantidadVenta = pv.cantidadVenta;

    const precioVenta = pv.precio * pv.cantidadVenta;

    const PRODUCTO_RUTA = pv.PRODUCTO_RUTA;

    return { productoId, cantidadVenta, precioVenta, PRODUCTO_RUTA };
  });

  return nuevosProductosVenta;
};

export const calcularMonto = (tipoPago, nuevosProductosVenta) => {
  if (tipoPago === "CORTESIA") {
    return 0;
  } else {
    const monto = nuevosProductosVenta.reduce(
      (total, pv) => pv.precioVenta + total,
      0
    );

    return monto;
  }
};

export const crearClientesVenta = (salidaRuta) => {
  const nuevaSalidaRuta = deepCopy(salidaRuta);

  console.log(
    "Precios en salidaRuta antes de copiar:",
    salidaRuta.clientes[0].precios_cliente
  );

  console.log(
    "Precios en salidaRuta después de copiar:",
    nuevaSalidaRuta.clientes[0].precios_cliente
  );

  // console.log("clientes::", nuevaSalidaRuta.clientes);
  // console.log("productos::", nuevaSalidaRuta.productos);

  nuevaSalidaRuta.clientes.forEach((cliente) => {
    const nuevosPreciosCliente = [];

    const preciosActuales = cliente.precios_cliente;

    preciosActuales.forEach((precio) => {
      const productos = nuevaSalidaRuta.productos;

      const producto = productos.find(
        (prod) =>
          prod.PRODUCTO_RUTA === precio.productoId && prod.STATUS === "CARGADO"
      );

      if (producto) {
        precio.id = producto.id;
        precio.producto_cantidad = producto.CANTIDAD_DISPONIBLE;
        precio.STATUS = producto.STATUS;
        precio.PRODUCTO_RUTA = producto.PRODUCTO_RUTA;
        nuevosPreciosCliente.push(precio);
      }
    });
    cliente.precios_cliente = nuevosPreciosCliente;
  });

  // Regreso el la clientes con los precios de los productos que tiene status de cargado y ademas anadi los campos id, producto_cantidad, STATUS
  return nuevaSalidaRuta.clientes;
};

const deepCopy = (obj) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  if (Array.isArray(obj)) {
    const copy = [];
    obj.forEach((el) => {
      copy.push(deepCopy(el));
    });
    return copy;
  }
  if (typeof obj === "object") {
    const copy = {};
    Object.keys(obj).forEach((key) => {
      copy[key] = deepCopy(obj[key]);
    });
    return copy;
  }
};

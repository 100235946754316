import React, { useRef } from "react";
import { Form } from "react-bootstrap";

import {
  StyledBoton,
  StyledForm,
  StyledFormGroup,
} from "./styles/FormularioVenta.styles";
import { toast } from "react-hot-toast";
import {
  calcularMonto,
  crearProductosVentaConSubtotal,
} from "../../lib/utilis/ventaSalidaRuta";
import { useFormRealizarVenta } from "../../lib/hooks/useFormRealizarVenta";

const FormularioVenta = ({
  cliente,
  manejarCambiarCliente,
  clientesDisponibles,
  manejarLimpiarProductosVenta,
  productosVenta,
  manejarSeleccionarProducto,
  productosCliente,
  setVenta,
  setMostrarVenta,
  deshabilitarVenta,
}) => {
  // La razon por la que usamos useState y no useForm para este formulario es debido a que no se requiere validacion
  // Hooks para el formulario de venta

  const {
    tipoPago,
    status,
    observaciones,
    montoDescuento,
    setTipoPago,
    setStatus,
    setObservaciones,
    setMontoDescuento,
  } = useFormRealizarVenta();

  // SI VAS A RENDERIZAR AL APLICAR LOS CAMBIOS, PIENSA BIEN SI NECESITAS UN useEffect!
  const productosVentaConSubtotal =
    crearProductosVentaConSubtotal(productosVenta);

  const montoSinDescuento = calcularMonto(tipoPago, productosVentaConSubtotal);

  var porcentajeDescuento;
  if (montoDescuento / montoSinDescuento === 0) {
    porcentajeDescuento = 0;
  } else {
    porcentajeDescuento = (1 - montoDescuento / montoSinDescuento) * 100;
  }

  //   Funcion para crear venta
  const manejarCrearVenta = (e) => {
    e.preventDefault();

    if (porcentajeDescuento < 0) {
      return toast.error(
        "El porcentaje de descuento debe ser mayor o igual 0."
      );
    }

    if (status === "REALIZADO" && porcentajeDescuento > 0) {
      return toast.error(
        "STATUS deber ser pendiente si el tipo de venta es cortesía o existe un descuento"
      );
    }

    const ventaSubmit = {
      productosVenta: productosVentaConSubtotal,
      VENDEDOR: VENDEDOR,
      MONTO: montoDescuento ? montoDescuento : montoSinDescuento,
      TIPO_VENTA: "RUTA",
      TIPO_PAGO: tipoPago,
      STATUS: status,
      OBSERVACIONES: observaciones,
      CLIENTE: Number(cliente.CLIENTE_RUTA),
      NOMBRE_CLIENTE: cliente.CLIENTE_NOMBRE,
      DESCUENTO: porcentajeDescuento,
    };

    setVenta(ventaSubmit);
    setMostrarVenta(true);
  };

  const VENDEDOR = JSON.parse(localStorage.getItem("name"));

  const buttonRef = useRef(null);

  console.log("productos cliente", productosCliente);

  return (
    <StyledForm onSubmit={manejarCrearVenta}>
      <StyledFormGroup controlId="cliente">
        <Form.Label>CLIENTE</Form.Label>
        <Form.Control
          as="select"
          value={cliente.id}
          onChange={(e) => {
            manejarCambiarCliente(Number(e.target.value));
            manejarLimpiarProductosVenta();
          }}>
          {clientesDisponibles.map((c) => (
            <option key={c.id} value={c.id}>
              {c.CLIENTE_NOMBRE}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="productosCliente">
        <Form.Label>PRODUCTOS DEL CLIENTE</Form.Label>
        <Form.Control
          as="select"
          value={0}
          onChange={(e) => manejarSeleccionarProducto(Number(e.target.value))}>
          <option value={0}>Selecciona un producto</option>
          {productosCliente.map((pc) => (
            <option key={pc.id} value={pc.id}>
              {pc.producto_nombre}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="tipo_pago">
        <Form.Label>TIPO DE PAGO</Form.Label>
        <Form.Control
          as="select"
          value={tipoPago}
          onChange={(e) => setTipoPago(e.target.value)}>
          <option value="CONTADO">Efectivo</option>
          <option value="CREDITO">Credito</option>
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="status">
        <Form.Label>STATUS</Form.Label>
        <Form.Control
          as="select"
          value={status}
          onChange={(e) => setStatus(e.target.value)}>
          <option value="REALIZADO">Realizado</option>
          <option value="PENDIENTE">Pendiente</option>
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="observaciones">
        <Form.Label>OBSERVACIONES</Form.Label>
        <Form.Control
          required={porcentajeDescuento > 0}
          type="text"
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
          autoComplete="off"></Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="descuento">
        <Form.Label>MONTO DESCUENTO </Form.Label>
        <Form.Control
          required
          type="number"
          value={montoDescuento}
          min={0}
          step={0.01}
          autoComplete="off"
          onChange={(e) => setMontoDescuento(parseFloat(e.target.value))}
        />
      </StyledFormGroup>

      {montoDescuento ? (
        <p className="text-light fs-6 text-center">
          (Porcentaje Descuento: {porcentajeDescuento.toFixed(2)}%)
        </p>
      ) : (
        <p className="text-light fs-6 text-center">
          (Monto total: ${montoSinDescuento.toFixed(2)})
        </p>
      )}
      <StyledBoton
        color="green"
        disabled={deshabilitarVenta}
        type="submit"
        ref={buttonRef}
        onFocus={() => buttonRef.current.blur()}>
        Realizar venta
      </StyledBoton>
    </StyledForm>
  );
};

export default FormularioVenta;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import { toast } from "react-hot-toast";

import {
  cancelarSalidaRuta,
  obtenerSalidaRutaDetallesAcciones,
  realizarRecargaSalidaRuta,
} from "../actions/salidaRutaActions";
import {
  RESET_SALIDA_RUTA_ACTUALIZAR,
  RESET_SALIDA_RUTA_DETALLES,
  RESET_SALIDA_RUTA_RECARGA,
} from "../constantes/salidaRutaConstantes";

// Estilos de la pagina
import {
  StyledContainer,
  StyledRow,
  StyledCol,
  StyledBoton,
} from "./styles/SalidaRutaDetalles.styles";
import { Form, ListGroup } from "react-bootstrap";
import { pedirProductosLista } from "../actions/productoActions";
import { useReactToPrint } from "react-to-print";
import TicketSalidaRuta from "../componentes/SalidaRuta/TicketSalidaRuta";

const SalidaRutaDetalles = () => {
  // Funcion para disparar creador de acciones
  const dispatch = useDispatch();

  // Funciona para navegar a la lista de salidas ruta
  const navigate = useNavigate();

  // Obtener el id de la salida ruta
  const params = useParams();
  const salidaRutaId = params.id;

  // Obtener referencias de los botones
  const regresarRef = useRef(null);
  const cancelarRef = useRef(null);
  const componentRef = useRef(null);

  // Estado deshabilitacion del boton
  const [disabledState, setDsiabledState] = useState(false);

  const salidaRutaDetalles = useSelector((state) => state.salidaRutaDetalles);
  const { error, salidaRuta, loading } = salidaRutaDetalles;

  const salidaRutaActualizar = useSelector(
    (state) => state.salidaRutaActualizar
  );
  const {
    error: cancelarError,
    success: cancelarSuccess,
    loading: cancelarLoading,
  } = salidaRutaActualizar;

  // Obtener el estado desde el Redux store
  const salidaRutaRecarga = useSelector((state) => state.salidaRutaRecarga);
  const {
    loading: recargaLoading,
    success: recargaSuccess,
    error: recargaError,
  } = salidaRutaRecarga;

  // Obtener productos del Redxu store
  const productoLista = useSelector((state) => state.productoLista);
  const {
    // loading: productosLoading,
    productos,
    // error: productosError,
  } = productoLista;

  const [producto, setProducto] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [cantidadCargada, setCantidadCargada] = useState(0);
  const [salidaRutaTicket, setSalidaRutaTicket] = useState();

  const manejarSeleccionarProducto = (productoId) => {
    const productoSeleccionadoSalidaRuta = salidaRuta.productos.find(
      (p) => p.PRODUCTO_RUTA === Number(productoId)
    );

    if (productoSeleccionadoSalidaRuta)
      setCantidadCargada(productoSeleccionadoSalidaRuta.CANTIDAD_DISPONIBLE);
    else {
      setCantidadCargada(0);
    }
    const productoSeleccionadoAlmacen = productos.find(
      (p) => p.id === Number(productoId)
    );

    if (productoSeleccionadoAlmacen) {
      setProducto(productoSeleccionadoAlmacen);
    } else {
      setProducto({});
    }
  };

  useEffect(() => {
    if (cancelarLoading) {
      toast.loading("Cancelando salida ruta");
    }

    if (cancelarSuccess) {
      toast.dismiss();
      toast.success(
        "Salida ruta y sus productos y clientes han sido cancelados exitosamente. El producto de la salida a ruta ha sido devuelto al stock",
        {
          duration: 2000,
        }
      );

      dispatch({ type: RESET_SALIDA_RUTA_ACTUALIZAR });
      navigate("/salida-rutas");
    }

    if (cancelarError) {
      toast.dismiss();
      toast.error("Error al cancelar salida ruta");
    }
  }, [cancelarError, cancelarLoading, cancelarSuccess, dispatch, navigate]);

  useEffect(() => {
    if (recargaLoading) {
      toast.loading("Realizando recarga de salida ruta");
    }

    if (recargaSuccess) {
      toast.dismiss();
      toast.success("Salida ruta ha sido actualizada exitosamente", {
        duration: 2000,
      });
    }

    if (recargaError) {
      toast.dismiss();
      toast.error("Error al realizar recarga de salida ruta");
    }
  }, [recargaLoading, recargaSuccess, recargaError, dispatch, navigate]);

  // useEffect para pedir productos del Redux store
  useEffect(() => {
    if (!productos) {
      dispatch(pedirProductosLista());
    }
  }, [dispatch, productos]);

  useEffect(() => {
    if (!salidaRuta || salidaRuta.id !== Number(salidaRutaId)) {
      dispatch(obtenerSalidaRutaDetallesAcciones(salidaRutaId));
    } else {
      setSalidaRutaTicket({ ...salidaRuta });
    }
  }, [salidaRuta, salidaRutaId, navigate, dispatch]);

  const manejarCancelarSalidaRuta = () => {
    // Disparar la accion de cancelar salida ruta
    setDsiabledState(!disabledState);
    dispatch(cancelarSalidaRuta(salidaRutaId));
  };

  const manejarRecargaSalidaRuta = (e) => {
    e.preventDefault();
    setDsiabledState(!disabledState);
    toast.dismiss();
    if (cantidad > producto.CANTIDAD) {
      toast.error(
        `La cantidad de producto a cargar debe ser menos que la cantidad disponible ${producto.CANTIDAD}`
      );

      return;
    } else if (cantidad === 0) {
      toast.error("Cantidad de producto a cargar debe ser mayor a 0");
      return;
    }

    const recarga = {
      SALIDA_RUTA: salidaRuta.id,
      PRODUCTO_RUTA: producto.id,
      PRODUCTO_NOMBRE: producto.NOMBRE,
      CANTIDAD_RECARGA: cantidad,
      CANTIDAD_RUTA: cantidadCargada + cantidad,
      CANTIDAD_DISPONIBLE: cantidadCargada + cantidad,
      STATUS: "CARGADO",
    };
    dispatch(realizarRecargaSalidaRuta(recarga, salidaRutaId));
  };

  const manejarRegresar = () => {
    dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    navigate("/salida-rutas");
  };

  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,

    onAfterPrint: () => {
      dispatch({ type: RESET_SALIDA_RUTA_RECARGA });
      navigate("/salida-rutas");
    },
  });

  useEffect(() => {
    if (recargaSuccess) {
      manejarPrint();
    }
  }, [manejarPrint, recargaSuccess]);

  if (loading) return <Loader />;

  if (error) return <Mensaje variant="danger">{error}</Mensaje>;

  return (
    salidaRuta && (
      <>
        <StyledContainer fluid>
          <StyledRow>
            <StyledCol>
              <h1>Salida Ruta #{salidaRutaId}</h1>
              <div>
                <StyledBoton
                  variant="primary"
                  color="green"
                  ref={regresarRef}
                  onFocus={() => regresarRef.current.blur()}
                  onClick={manejarRegresar}>
                  Regresar
                </StyledBoton>
              </div>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            {salidaRuta.STATUS === "PENDIENTE" ? (
              <StyledCol>
                <h3>¿Está seguro de cancelar la salida a ruta?</h3>
                <p>
                  Todos los producto y clientes de la salida ruta serán
                  cancelados
                </p>
                <p>Los productos de la salida ruta serán devueltos al stock</p>

                <div>
                  <StyledBoton
                    color="green"
                    ref={cancelarRef}
                    disabled={disabledState}
                    onFocus={() => cancelarRef.current.blur()}
                    onClick={manejarCancelarSalidaRuta}>
                    Cancelar Salida Ruta
                  </StyledBoton>
                </div>
              </StyledCol>
            ) : (
              <>
                <StyledCol style={{ marginLeft: "1.2rem" }}>
                  <Form onSubmit={manejarRecargaSalidaRuta}>
                    <Form.Group controlId="repartidor">
                      <Form.Label>Producto</Form.Label>
                      <Form.Control
                        as="select"
                        value={producto.id}
                        onChange={(e) =>
                          manejarSeleccionarProducto(e.target.value)
                        }
                        required>
                        <option value="">Selecciona un producto</option>
                        {productos &&
                          productos.map((p) => (
                            <option key={p.id} value={p.id}>
                              {p.NOMBRE}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="nombre">
                      <Form.Label>Cantidad a cargar</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        disabled={!producto.id}
                        value={cantidad}
                        onChange={(e) => {
                          if (e.target.value === "" || e.target.value < 0) {
                            setCantidad("");
                          } else {
                            const qty = Number(e.target.value);
                            setCantidad(qty);
                            const productoRecarga = {
                              ...salidaRuta.productos.find(
                                (p) => p.PRODUCTO_RUTA === producto.id
                              ),
                            };

                            productoRecarga.PRODUCTO_NOMBRE = producto.NOMBRE;
                            productoRecarga.CANTIDAD_RUTA = qty;

                            setSalidaRutaTicket({
                              ...salidaRutaTicket,
                              productos: [productoRecarga],
                            });
                          }
                        }}></Form.Control>
                    </Form.Group>

                    <div>
                      <StyledBoton
                        type="submit"
                        color="green"
                        disabled={disabledState}
                        ref={cancelarRef}
                        onFocus={() => cancelarRef.current.blur()}>
                        Realizar recarga
                      </StyledBoton>
                    </div>
                  </Form>
                </StyledCol>
                <StyledCol>
                  <ListGroup
                    style={{
                      boxShadow: "2px 2px 5px 2px rgba(0,0,0,0.7)",
                      marginLeft: "1.5rem",
                    }}>
                    <ListGroup.Item style={{ width: "300px" }}>
                      <p>CANTIDAD DISPONIBLE: {producto.CANTIDAD}</p>
                      <div
                        style={{ backgroundColor: "gray", height: "1.5px" }}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item style={{ width: "300px" }}>
                      <p>CANTIDAD ACTUAL EN SALIDA RUTA: {cantidadCargada}</p>
                      <div
                        style={{ backgroundColor: "gray", height: "1.5px" }}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item style={{ width: "300px" }}>
                      <p>
                        NUEVA CANTIDAD EN SALIDA RUTA:
                        {cantidadCargada + cantidad}
                      </p>
                    </ListGroup.Item>
                  </ListGroup>
                </StyledCol>
              </>
            )}
          </StyledRow>
        </StyledContainer>
        <div style={{ display: "none" }}>
          <TicketSalidaRuta
            datosSalidaRuta={salidaRutaTicket}
            titulo="RECARGA"
            ref={componentRef}
          />
        </div>
      </>
    )
  );
};

export default SalidaRutaDetalles;

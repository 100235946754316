import React, { forwardRef } from "react";
import { BASE_URL } from "../../constantes/constantes";
import {
  StyledContenedorProducto,
  StyledGridDosSecciones,
  StyledLogo,
  StyledContenedorLogo,
  StyledPrincipal,
  StyledTexto,
} from "./styles/TicketSalidaRuta.styles";
import {
  getFormatoFecha,
  getFormatoHora,
  obtenerFechaActualConFormato,
} from "../../lib/utilis/salidaRutas";
import Separador from "../general/Separador";

const TicketSalidaRuta = forwardRef((props, ref) => {
  const { datosSalidaRuta, titulo } = props;

  //   const datosSalidaRuta = null;

  if (datosSalidaRuta) {
    const productos = datosSalidaRuta.productos;

    return (
      <StyledPrincipal ref={ref}>
        <StyledContenedorLogo>
          <StyledLogo src={`${BASE_URL}/static/imagenes/general/logo.png`} />
        </StyledContenedorLogo>
        <StyledTexto textAlign={"center"} fontSize={"20px"} bottom={"-6px"}>
          VALE DE {titulo ? titulo : "CARGA"}
        </StyledTexto>
        <Separador doble={true} />

        <StyledGridDosSecciones>
          <StyledTexto
            textAlign={"left"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            {getFormatoFecha(datosSalidaRuta.FECHA)}
          </StyledTexto>
          <StyledTexto
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            {getFormatoHora(datosSalidaRuta.FECHA)}
          </StyledTexto>
        </StyledGridDosSecciones>

        <StyledTexto
          fontWeight={600}
          textAlign={"left"}
          fontSize={"13.5px"}
          top={"0px"}
          bottom={"1px"}
        >
          ID: {datosSalidaRuta.id}
        </StyledTexto>

        <StyledTexto
          fontWeight={600}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={400}
          top={"4px"}
          bottom={"0px"}
        >
          ENTREGA:
          <br />
          <br />
          <p style={{ marginBottom: "0px" }}>
            ________________________________
          </p>
          <span>{datosSalidaRuta.ATIENDE}</span>
        </StyledTexto>

        <StyledTexto
          fontWeight={600}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={400}
          top={"7px"}
          bottom={"-2px"}
        >
          RECIBE:
          <br />
          <br />
          <p style={{ marginBottom: "0px" }}>
            ________________________________
          </p>
          <span>{datosSalidaRuta.REPARTIDOR_NOMBRE}</span>
        </StyledTexto>

        <Separador doble={true} />

        <StyledContenedorProducto>
          <StyledTexto
            fontWeight={600}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            PRODUCTO
          </StyledTexto>
          <StyledTexto
            fontWeight={600}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            CANT.
          </StyledTexto>
        </StyledContenedorProducto>

        <StyledContenedorProducto>
          {productos.map((producto) => {
            return (
              <>
                <StyledTexto
                  fontWeight={600}
                  textAlign={"left"}
                  fontSize={"13.5px"}
                  top={"-3px"}
                  bottom={"-1px"}
                >
                  {producto.PRODUCTO_NOMBRE}
                </StyledTexto>
                <StyledTexto
                  fontWeight={600}
                  textAlign={"center"}
                  fontSize={"13.5px"}
                  top={"-1px"}
                  bottom={"0px"}
                >
                  {producto.CANTIDAD_RUTA}
                </StyledTexto>
              </>
            );
          })}
        </StyledContenedorProducto>
        <Separador doble={true} />

        <StyledTexto
          textAlign={"left"}
          fontSize={"14px"}
          top={"0px"}
          bottom={"1px"}
        >
          FECHA DE REIMPRESION: {obtenerFechaActualConFormato()}
        </StyledTexto>

        {!titulo && (
          <>
            <Separador />
            <StyledTexto
              textAlign={"left"}
              fontSize={"14px"}
              top={"0px"}
              bottom={"1px"}
            >
              <p>OBSERVACIONES: </p>
              <span>{datosSalidaRuta.OBSERVACIONES}</span>
            </StyledTexto>
          </>
        )}
      </StyledPrincipal>
    );
  } else {
    return (
      <StyledPrincipal ref={ref}>
        <h3> No existen datos de salida ruta </h3>
      </StyledPrincipal>
    );
  }
});

export default TicketSalidaRuta;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Funciones
import { obtenerSalidaRutaResumen } from "../actions/salidaRutaActions";
import useScreenSize from "../componentes/general/UseScreenSize";
import { toast } from "react-hot-toast";

// Componentes de la pagina
import {
  Main,
  Contenido,
  Productos,
  Clientes,
  Header,
  Selector,
  Board,
  SelectorButton,
  StyledBotonContainer,
} from "./styles/ResumenSalidaRuta.styles";
import {
  TablaClientes,
  TablaProductos,
} from "../componentes/ResumenSalidaRuta";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import {
  RESET_SALIDA_RUTA_DETALLES,
  RESET_SALIDA_RUTA_DEVOLUCION,
} from "../constantes/salidaRutaConstantes";
import { StyledBoton } from "./styles/InicioSesion.styles";
import TicketDevolucion from "../componentes/ResumenSalidaRuta/TicketDevolucion";
import { useReactToPrint } from "react-to-print";

const ResumenSalidaRuta = ({ match }) => {
  // Obtener el id de la salida
  const params = useParams(match);
  const salidaRutaId = params.id;

  const navigate = useNavigate();
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Obtener la salida a rutas desde el Redux store
  const salidaRutaDetalles = useSelector((state) => state.salidaRutaDetalles);
  const { loading, salidaRuta, error } = salidaRutaDetalles;

  const salidaRutaDevolucion = useSelector(
    (state) => state.salidaRutaDevolucion
  );
  const {
    loading: devolucionLoading,
    devolucion,
    error: devolucionError,
  } = salidaRutaDevolucion;

  // Hook para monitorear tamaño de pantalla
  const { ancho, alto } = useScreenSize();

  // Obtener referencias de los botones
  const regresarRef = useRef(null);
  const componentRef = useRef(null);

  // Estado de seleccion de seccion
  const [productsSelected, setProductsSelected] = useState(true);
  const [clientsSelected, setClientsSelected] = useState(false);

  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,

    onAfterPrint: () => {
      dispatch({ type: RESET_SALIDA_RUTA_DEVOLUCION });
      navigate("/salida-rutas");
    },
  });
  // Si no existe la informacion de la salida a ruta pedirla del servidor
  useEffect(() => {
    if (!salidaRuta || salidaRuta.id !== Number(salidaRutaId)) {
      dispatch(obtenerSalidaRutaResumen(salidaRutaId));
    }
  }, [dispatch, salidaRutaId, salidaRuta]);

  useEffect(() => {
    if (devolucionLoading) {
      toast.loading("Realizando devolucion de salida ruta");
    }

    if (devolucion) {
      toast.dismiss();
      toast.success("Salida ruta ha sido actualizada exitosamente", {
        duration: 2000,
      });
    }

    if (devolucionError) {
      toast.dismiss();
      toast.error("Error al realizar devolucion de salida ruta");
    }
  }, [devolucionLoading, devolucion, devolucionError, dispatch, navigate]);

  useEffect(() => {
    if (devolucion) {
      manejarPrint();
    }
  }, [manejarPrint, devolucion]);

  const manejarRegresar = () => {
    dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    navigate("/salida-rutas");
  };

  // Si esta cargando la informacion
  if (loading) {
    return <Loader />;
  }

  // Si ocurrio un error
  if (error) {
    return (
      <Main>
        <Mensaje variant="danger">
          Hubo un error al cargar la informacion de la salida a ruta
        </Mensaje>
      </Main>
    );
  }

  return (
    salidaRuta && (
      <>
        <Main>
          <Header>
            <h1>Salida Ruta #{salidaRutaId}</h1>
            <StyledBotonContainer>
              <StyledBoton
                color="green"
                ref={regresarRef}
                onFocus={() => regresarRef.current.blur()}
                onClick={manejarRegresar}>
                Regresar
              </StyledBoton>
            </StyledBotonContainer>
          </Header>

          <Contenido ancho={ancho}>
            {ancho > 768 ? (
              <>
                <Productos>
                  <h3>Productos cargados</h3>
                  <TablaProductos
                    productos={salidaRuta.productos}
                    salidaRuta={salidaRuta}
                  />
                </Productos>

                <div
                  style={{
                    background: "white",
                    width: "3px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />

                <Clientes>
                  <h3>Clientes </h3>
                  <TablaClientes
                    clientes={salidaRuta.clientes}
                    salidaRuta={salidaRuta}
                  />
                </Clientes>
              </>
            ) : (
              <>
                <Selector>
                  <SelectorButton
                    side={"left"}
                    selected={productsSelected}
                    onClick={() => {
                      setProductsSelected(!productsSelected);
                      setClientsSelected(!clientsSelected);
                    }}
                    disabled={productsSelected}>
                    Productos
                  </SelectorButton>
                  <SelectorButton
                    selected={clientsSelected}
                    onClick={() => {
                      setClientsSelected(!clientsSelected);
                      setProductsSelected(!productsSelected);
                    }}
                    disabled={clientsSelected}>
                    Clientes
                  </SelectorButton>
                </Selector>
                <Board>
                  {productsSelected ? (
                    <TablaProductos
                      productos={salidaRuta.productos}
                      salidaRuta={salidaRuta}
                    />
                  ) : clientsSelected ? (
                    <TablaClientes
                      clientes={salidaRuta.clientes}
                      salidaRuta={salidaRuta}
                    />
                  ) : null}
                </Board>
              </>
            )}
          </Contenido>
        </Main>
        <div style={{ display: "none" }}>
          <TicketDevolucion datosDevolucion={devolucion} ref={componentRef} />
        </div>
      </>
    )
  );
};

export default ResumenSalidaRuta;
